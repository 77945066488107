import { title } from '@/utilities/filters'
import { getFormattedDateTime, getFormattedJourneyReference } from '@/utilities/org'

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return [
    {
      renderFunction: (v: any) => getFormattedJourneyReference(v),
      text: title(String(context.$t('common.reference'))) + '.',
      value: 'jobNumber',
      vueComponent: {
        name: 'c-copy-to-clipboard',
        props: { raw: true },
      },
      width: '6%',
    },
    {
      renderFunction: (v: any) => getFormattedDateTime(v),
      text: title(String(context.$t('common.date'))),
      value: 'departureDateOffset',
      width: '6%',
    },
    {
      text: context.$t('cmac.passenger'),
      value: 'leadPassengerName',
      width: '10%',
    },
    {
      text: context.$t('cmac.vehicle'),
      value: 'vehicleDescription',
      width: '10%',
    },
    {
      raw: true,
      text: title(context.$tc('plurals.journeys', 1)),
      value: 'waypoints',
      vueComponent: { name: 'c-waypoints' },
      width: '20%',
    },
    {
      renderFunction: (v: any) => Number(v).toFixed(2),
      text: context.$t('cmac.leadMiles'),
      value: 'leadMiles',
      width: '5%',
    },
    {
      renderFunction: (v: any) => Number(v).toFixed(2),
      text: context.$t('cmac.leadMilesCost'),
      value: 'leadMileCost',
      width: '5%',
    },
    {
      renderFunction: (v: any) => Number(v).toFixed(2),
      text: context.$t('cmac.fareNet'),
      value: 'costSourceNet',
      width: '5%',
    },
    {
      renderFunction: (v: any) => Number(v).toFixed(2),
      text: context.$t('cmac.totalCost'),
      value: 'totalCostSourceNet',
      width: '5%',
    },
    {
      renderFunction: (v: any) => {
        const driverDetails = []

        if (v?.name) driverDetails.push(v.name)
        if (v?.phoneNumber) driverDetails.push(v.phoneNumber)
        if (v?.vehicleReg) driverDetails.push(v.vehicleReg)
      
        return driverDetails.join(', ');
      },
      text: title(String(context.$t('cmac.driverDetails'))),
      value: 'driver',
      width: '20%',
    },
    { text: title(String(context.$t('common.info'))), value: 'notes', width: '13%' },
    { 
      vueComponent: { 
        name: 'c-edit-journey',
        props: { raw: true },
       },
      value: 'edit', 
      width: '5%' 
    },
    ]
}
