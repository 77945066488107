
import { Component, Watch, Vue } from 'vue-property-decorator'
import EditJourney from '@/pages/journeys/EditJourney.vue'
import { DateTime } from 'luxon'
import cloneDeep from 'lodash/cloneDeep'
import { DEFAULT_DAY_RANGES, DEFAULT_TABLE_OPTIONS } from '@/utilities/constants'
import { headers, items } from '@/pages/journeys/helpers/index'
import { disableSorting } from '@/utilities/org'
import api from '@/utilities/api'
import { downloadDirectToBrowser } from '@/utilities/functions'

Vue.component('c-edit-journey', EditJourney)

const { now } = DateTime

@Component
export default class Journeys extends Vue {
  // data // ***
  dateHasRecentlyChanged = false
  dates: DateTime[] = [now(), now().plus({ days: DEFAULT_DAY_RANGES.JOURNEY })]
  initialLoadCompleted = false
  options: Record<string, any> = cloneDeep(DEFAULT_TABLE_OPTIONS)
  searchFields: Record<string, any> = { cmacRef: null, externalRef: null }
  selected: any[] = []

  // computed // ***
  get activeSuppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/activeSuppliers'].map((v: number) => this.suppliers[v])
  }
  get activeSupplierIds(): string[] {
    return this.activeSuppliers.map((v: Record<string, any>) => v.id)
  }
  get fetchLoading(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get headers(): Record<string, any>[] {
    return disableSorting(headers(this))
  }
  get items(): Record<string, any>[] {
    return items(this)
  }
  get paginationInfo() {
    const j: Record<string, any> = this.$store.getters['suppliers/currentJourneys'] || {}
    return {
      page: (j.pageIndex || 0) + 1,
      returnedRecords: j.returnedRecords || 0,
      total: j.totalRecords || 0,
    }
  }
  get journeys(): Record<string, any>[] {
    return this.$store.getters['suppliers/currentJourneys']?.journeys || []
  }
  get loading(): boolean {
    return this.fetchLoading || this.updateLoading
  }
  get mobileBreakpoint(): number {
    return 1270
  }
  get queryParams() {
    const { dates } = this
    const organisationIds =
      this.activeSuppliers.length >= 1 ? this.activeSupplierIds : [String(this.supplier?.id)]
    const dateFrom = dates[0].toISODate()
    const dateTo = this.dates[1]?.toISODate() || cloneDeep(dates[0]).plus({ days: 1 }).toISODate()
    return {
      CmacRef: this.searchFields.cmacRef,
      dateFrom,
      dateTo,
      ExternalId: this.searchFields.externalRef,
      organisationIds,
      pageIndex: (this.options.page || 1) - 1,
      pageSize: this.options.itemsPerPage,
    }
  }
  get searchFieldProps() {
    return {
      cmacRef: { label: this.$t('cmac.cmacRef') },
      externalRef: { label: this.$t('cmac.yourRef') },
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get suppliers(): Record<string, any>[] {
    return this.$store.getters['suppliers/suppliers']
  }
  get tableHeaderShouldBeFixed() {
    return this.$vuetify.breakpoint.width >= this.mobileBreakpoint
  }
  get tableItems() {
    return this.items.map(i => ({
      ...i,
      itemSpecificConfig: {
        ...(i.itemSpecificConfig || {}),
        actions: { vueComponent: { props: {} } },
      },
    }))
  }
  get updateLoading(): boolean {
    const { update } = this.urls
    return this.journeys.reduce((a, v: Record<string, any>) => {
      const url = update(v?.id)
      if (a) return true
      else return this.$store.getters['core/apiEndpointIsLoading']({ method: 'PATCH', url })
    }, false)
  }
  get urls(): Record<string, any> {
    return {
      fetch: `supplier/currentJourneys`,
      update: (id: Record<string, any>) => `supplier/currentJourney/${id}/driver`,
      download: `supplier/currentJourneys/download`,
    }
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.onCreated()
  }
  async onCreated() {
    if (this.supplier?.id) this.fetch(true)
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }
  @Watch('loading')
  onLoadingChanged(v: boolean) {
    if (!v) this.initialLoadCompleted = true
  }
  @Watch('options.page')
  onPageChanged(n: number, o: number) {
    if (n !== o) this.fetch()
  }
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch(true)
  }

  // methods // ***
  fetch(resetPagination = false) {
    if (!this.loading) {
      const { fetch: url } = this.urls
      this.resetOptions(resetPagination)
      this.$store.dispatch('suppliers/fetchCurrentJourneys', { params: this.queryParams, url })
    }
  }
  onDatePickerClose() {
    if (this.dateHasRecentlyChanged) this.fetch()
    this.dateHasRecentlyChanged = false
  }
  onDatePickerInput() {
    this.dateHasRecentlyChanged = true
  }
  onPageInput(e: number) {
    if (e !== this.options.page) {
      this.options.page = e
      this.fetch()
    }
  }
  onPageSizeInput(e: number) {
    const currentItemsPerPage = this.options.itemsPerPage
    const currentPage = this.options.page
    if (e !== currentItemsPerPage) {
      this.options.itemsPerPage = e
      if (currentPage === 1) this.fetch()
      else this.options.page = 1
    }
  }
  onSave(e: Record<string, any>) {
    this.updateJourney(e)

    // Update any other selected (and editable) journeys with the driver details
    if (this.selected.length > 0) {
      const journeysToUpdate = this.selected.filter(journey => {
        return journey.id != e.item.id && journey.isEditable
      })

      if (journeysToUpdate.length > 0) {
        journeysToUpdate.forEach(journey => {
          this.updateJourney({ item: journey, values: e.values })
        })
      }
    }
  }
  onSearch() {
    this.fetch(true)
  }
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('journeys')) this.$router.push('/errors/404')
  }
  resetOptions(resetPagination = false) {
    const newOptions = cloneDeep(DEFAULT_TABLE_OPTIONS)
    const { page, itemsPerPage } = this.options
    newOptions.itemsPerPage = itemsPerPage
    this.selected = []
    if (resetPagination) this.options.page = 1
    else newOptions.page = page
    this.setOptions(newOptions)
  }
  rowIsLoading(i: Record<string, any>): boolean {
    const { update } = this.urls
    const url = update(i?.id)
    return this.$store.getters['core/apiEndpointIsLoading']({ url, method: 'PATCH' })
  }
  setCurrentJourney(item: Record<string, any>, values: Record<string, any>) {
    this.$store.commit('suppliers/setCurrentJourney', { item, values })
  }
  setOptions(o: any) {
    Vue.set(this, 'options', o)
  }
  async updateJourney(e: Record<string, any>): Promise<Record<string, any> | void> {
    const { item, values } = e
    const { update } = this.urls
    const url = update(item?.id)
    const data = {
      name: values?.name,
      phoneNumber: values?.phoneNumber,
      vehicleReg: values?.registration,
    }

    //update the selected journey as well
    this.selected = this.selected.map(j => {
      if (j.id === item.id) {
        return {
          ...j,
          driver: {
            name: values?.name,
            phoneNumber: values?.phoneNumber,
            vehicleReg: values?.registration,
          },
        }
      }
      return j
    })

    api(url, { data, json: true, method: 'PATCH' }, undefined)
      .then(() => this.setCurrentJourney(item, data))
      .catch(e => console.error(e))
  }
  async downloadJourneys() {
    try {
      const payload = {
        journeys: this.selected,
      }
      const { download } = this.urls
      const res = await api(download, { method: 'POST' }, { data: payload, responseType: 'blob' })

      if (res && res.data) {
        downloadDirectToBrowser(res.data, 'export.xlsx')
      } else {
        console.error('Invalid response from the server')
      }
    } catch (error) {
      console.error('An error occurred while downloading journeys:', error)
    }
  }
}
