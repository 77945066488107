
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Edit extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  item!: Record<string, any>

  // data // ***
  value = false
  driver: Record<string, any> = {}
  jobReference: string = this.item.jobNumber?.externalId

  // computed // ***
  get isEditable(): boolean {
    return this.item.isEditable
  }

  // methods // ***
  showDialog() {
    this.value = true
    this.driver = {
      name: this.item.driver?.name || '',
      phoneNumber: this.item.driver?.phoneNumber || '',
      registration: this.item.driver?.vehicleReg || '',
    }
  }
  closeDialog() {
    this.value = false
  }
  saveEdits() {
    const values = this.driver
    const { item } = this
    this.$emit('save:cell', { item, values })
    this.closeDialog()
  }
}
