export const items = (context: Record<string, any>): Record<string, any>[] =>
  context.journeys?.map((i: Record<string, any>) => ({
    ...i,
    driverDetails: {},
    info: i.comments,
    itemSpecificConfig: {
      driverDetails: {
        vueComponent: {
          props: {
            loading: context.rowIsLoading(i),
          },
        },
      },
      jobNumber: {
        vueComponent: {
          props: {
            copyValue: !i.externalId && i.externalId !== 0 ? undefined : String(i.externalId),
          },
        },
      },
      waypoints: { vueComponent: { props: { waypoints: i.waypoints } } },
    },
    jobNumber: { externalId: i.externalId, jobNumber: i.jobId, journeyNumber: i.id },
  })) || []
